
export default {
  head() {
    return this.$metadata(this.story)
  },
  data() {
    return {
      story: {}
    }
  },
  mounted() {
    this.$storybridge.on(['input', 'published', 'change'], (event) => {
      if (event.action === 'input') {
        if (event.story.id === this.story.id) {
          this.story.content = event.story.content
        }
      } else {
        window.location.reload()
      }
    })
  },
  async asyncData({ $storyblok, store, params, error }) {
    try {
      const story = await $storyblok.getStory('news-and-community')
      return {
        story: story
      }
    } catch (error) {
      console.error(error)
    }
  }
}
